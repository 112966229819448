import { Component, OnInit } from '@angular/core';
import {
	useDefaultDateLocale,
	NotificationService,
	PageLoaderService,
	BreadcrumbService,
	AppBaseComponent,
	LanguageService,
	PageService,
	AppLanguage,
	onLine$,
	useDefaultTimezone,
} from '@aston/foundation';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd } from '@angular/router';
import { filter, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { NgbModal, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService, TranslatePipe } from '@ngx-translate/core';
import { defineLocale, enGbLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { frLocale, IPageAnchor } from '@aston/foundation';
import { Store } from '@ngrx/store';
import { timer } from 'rxjs';

import { InactivityChannelService, WebSocketService } from './shared-module/services';
import { injectFeaturesStore } from './factor-features-module/stores/factor-features';
import { selectTenantTimezone } from './root-store/referential-store/selectors';
import { AppStoreActions, AppStoreSelectors } from './root-store/app-store';
import { AuthenticationService } from './authentication-module/services';
import * as RoutesDefinitions from './routes-definitions';
import { AppConstants } from './app.constants';


@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	providers: [
		TranslatePipe
	]
})
export class AppComponent extends AppBaseComponent implements OnInit {
	ffs = injectFeaturesStore();

	pageAnchors: IPageAnchor[] = [];

	appIsReady$ = this.store.select(AppStoreSelectors.selectIsReady);
	bootstrapError$ = this.store.select(AppStoreSelectors.selectBootstrapError);

	location = window.location;
	onLine$ = onLine$;

	constructor(
		authenticationService: AuthenticationService,
		translateService: TranslateService,
		public translatePipe: TranslatePipe,
		languageService: LanguageService,
		pageService: PageService,
		protected ws: WebSocketService,
		notificationService: NotificationService,
		pageLoaderService: PageLoaderService,
		private bsLocaleService: BsLocaleService,
		route: ActivatedRoute,
		public modal: NgbModal,
		router: Router,
		private inactivityService: InactivityChannelService,
		private breadcrumbService: BreadcrumbService,
		private ngbTooltipConfigService: NgbTooltipConfig,
		private store: Store) {
		super(translateService, languageService, pageService, notificationService, pageLoaderService, route, router);

		defineLocale(AppLanguage.FR, frLocale);
		defineLocale(AppLanguage.EN, enGbLocale);

		// TODO: Find a cleaner way to do this
		// when the user come back, try to restore its auth state
		// but avoid doing this on auth callback page.
		// cf https://github.com/damienbod/angular-auth-oidc-client/issues/483
		authenticationService.getIsAuthorized().pipe(
			tap(auth => auth && this.ws.connect()),
			takeUntil(this.destroySubscriptions$),
		).subscribe();

		languageService.language$.subscribe(current => {
			this.bsLocaleService.use(current);
			useDefaultDateLocale(current)
		});

		store.select(selectTenantTimezone)
			.pipe(
				filter(Boolean),
				takeUntil(this.destroySubscriptions$)
			)
			.subscribe(tz => useDefaultTimezone(tz));

		pageService.pageAnchors$.subscribe(anchors => {
			this.pageAnchors = anchors;
		});

		router.events.pipe(
			filter(event => event instanceof NavigationStart),
			filter(_ => this.modal.hasOpenModals())
		).subscribe(_ => {
			if (window?.alert) alert(this.translatePipe.transform('App.NavigationMessage'));
			this.modal.dismissAll();
		});

		router.events.pipe(
			filter(event => event instanceof NavigationEnd),
		).subscribe(_ => {
			pageService.resetAnchors();

			let url = window.location.pathname;

			if (!url.includes(RoutesDefinitions.AuthenticationRoutePath)) {
				const tenantId = authenticationService.getTenantId();

				// the tenant id is not a part of the route so we remove it from the stored one
				url = url.replace('/' + tenantId + '', '');
				this.store.dispatch(AppStoreActions.UpdateUserSessionRequest({source: `navigating ${url}`, userSession: {lastRoute: url}}));
			}
		});

		translateService.get('Filters.DateExpression')
							 .pipe(take(1))
							 .subscribe(keys => {
								 AppConstants.updateDateExpressionWithTranslation(keys);
							 });

		// Bug 26022: [ITS] [MO-HISTORIQUE DES IMPORTS] : Persistance des tooltips suite à fermeture de la popin de visualisation d'un rapport
		// To prevent tooltip (on buttons) from re-opening after closing a modal that got open when clicking the button
		this.ngbTooltipConfigService.triggers = 'hover';
	}


	ngOnInit() {
		super.ngOnInit();

		//Improvement 24455: [ACC][Fil d'ariane] Homogéneiser les breadcrumbs de l'application
		this.breadcrumbService.setIgnoredDepth(1);

		timer(0, AppConstants.SECOND)
		.pipe(
			withLatestFrom(this.inactivityService.isInactive$),
			filter(([_, isInactive]) => !isInactive)
		)
		.subscribe(_ => {
			this.store.dispatch(AppStoreActions.UpdateInactivityTimer());
		});
	}
}
